import  './modules/_core_functions.js';
import  './modules/_intersection_observer.js';
import  './modules/_ajax_categories.js';
import  './modules/_ajax_blogs.js';
import './modules/_video_popup_modal.js'
import './modules/_close_banner.js';

import { Toast }  from './modules/_toast.js';

var $ = jQuery;
var body = $('body');
var mainNav = $('#main_menu');

$(document).ready(function() {

function initSlick() {
  if ($(window).width() < 1009) { // width minus scrollbar width
    if ($("[data-init-slick]").length > 0) {
		// Loop through data-init-slick elements and slick
		$("[data-init-slick]").each(function() {
			$(this).not('.slick-initialized').slick();
		});
      // $("[data-init-slick]").slick();
    }
  } else {
    if ($("[data-init-slick]").hasClass("slick-initialized")) {
      $("[data-init-slick]").slick("unslick");
    }
  }
}
 initSlick();

window.addEventListener(
  "pageshow",
  function (evt) {
    if (evt.persisted) {
    //   setTimeout(function () {
        // window.location.reload();
		 if ($("[data-init-slick]").hasClass("slick-initialized")) {
       		$("[data-init-slick]").slick("unslick");
	    initSlick();

     }
    //   }, 1);
    }
  },
  false
);
// Media query functions
if( $(window).width() < 767) {
}
if( $(window).width() < 1023) {
}
if( $(window).width() > 767 && $(window).width() < 1023 ) {
}

// Scroll functions
$(window).on("scroll", function () {

}).scroll();

function isInViewport(param) {
	if (param.length) {
		var contentViewTop = $(window).scrollTop();
		var contentViewBottom = contentViewTop + $(window).height();
		var scrolltop = $(param).offset().top;
		var elementBottom = scrolltop + $(param).height();
		return ((elementBottom <= contentViewBottom) && (scrolltop >= contentViewTop));
	}
}

// Resize functions
$(window).on("resize", function () {
 	initSlick();

	// if( $(window).width() > 768) {
	//
	// }
	// if ($(window).width() < 1024) {
	//
  	// }
	// if( $(window).width() < 1439) {
	//
	// }
	//
	// if( $(window).width() > 1440) {
	//
	// }
}).resize();


function navigationHamburger(){
	$('#hamburger').click(function() {
		if (!body.hasClass('menu-open')) {
			$(this).find('span:nth-child(1)').addClass('rotate-45').removeClass('-translate-y-1.5');
			$(this).find('span:nth-child(2)').addClass('opacity-0');
			$(this).find('span:nth-child(3)').addClass('-rotate-45').removeClass('translate-y-1.5');
			$('#mobile_menu').removeClass('hidden');
			body.addClass('menu-open body-no-scroll');
		} else if (body.hasClass('menu-open')) {
			$(this).find('span:nth-child(1)').removeClass('rotate-45').addClass('-translate-y-1.5');
			$(this).find('span:nth-child(2)').removeClass('opacity-0');
			$(this).find('span:nth-child(3)').removeClass('-rotate-45').addClass('translate-y-1.5');
			$('#mobile_menu').addClass('hidden');
			body.removeClass('menu-open body-no-scroll');
		}
	});
}
navigationHamburger();

function navigationSubmenu(){
	var hasSubMenu = $('.menu-item-has-children').children('.menu-link');
	var subMenu = $('.sub-menu')

	hasSubMenu.attr('href', 'javascript:void(0)');

	$(hasSubMenu).add($('.lang_current')).click(function(e) {
		$(subMenu).slideUp() && $(subMenu).parent().removeClass('active'),
		$(this).next().is(":visible") || 
		$(this).next().slideDown() && $(this).parent().addClass('active'),
		e.stopPropagation()
	});

	$(document).bind('click', function(e) {
		if($(e.target).closest('#special').length === 0) {
			$(subMenu).slideUp() && $(subMenu).parent().removeClass('active')
		}
	});

	var prevScrollpos = window.pageYOffset;
	window.onscroll = function() {
		var currentScrollPos = window.pageYOffset;
		if (prevScrollpos > currentScrollPos) {
			$(subMenu).slideUp() && $(subMenu).parent().removeClass('active')
		} else {
			$(subMenu).slideUp() && $(subMenu).parent().removeClass('active')

		}
		prevScrollpos = currentScrollPos;
	}
}
navigationSubmenu();


/*
**	Gravity Forms (AJAX)
**	Send a message once a Gravity Forms is succesfull submitted
**/
// window.top.$(document).on('gform_confirmation_loaded', function () {
// 	new Toast({
// 		title: 'Success',
// 		message: 'Your message was sent!',
// 		type: 'success'
// 	});
// });
/*
**	Gravity Forms (AJAX)
**	Send a message once a Gravity Forms has errors
**/
// $(document).ready(function(){
// 	var forms = $('.gform_wrapper');
// 	forms.each(function() {
// 		var id = this.id.replace('gform_wrapper_', '');
// 		// Check on ajax frame load to see if there are errors.
// 		$('#gform_ajax_frame_'+id).on('load',function(){
// 			if($("div.validation_error").length != 0){
// 				new Toast({
// 					title: 'Oops',
// 					message: 'Something went wrong, please check the filled in fields.',
// 					type: 'error'
// 				});
// 			}
// 		});
// 	});
// });


}); // Closing document.ready function, keep at the end of the file